import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'BLOK',
    lpAddresses: {
      25437: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      639054: '0x642a3f0Db764E4034C2C52658179805af285CB0C',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbcc,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'BLOK-BCC LP',
    lpAddresses: {
      25437: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      639054: '0x0cE325276d705254d461ecBCD2E210d4dB60a50a',
    },
    token: serializedTokens.blok,
    quoteToken: serializedTokens.wbcc,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'BCUSD-BCC LP',
    lpAddresses: {
      25437: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      639054: '0x3101ca7d8e255F1f1bB31Cb0183599d9Ba37da31',
    },
    token: serializedTokens.bcusd,
    quoteToken: serializedTokens.wbcc,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'BCUSDT-BCC LP',
    lpAddresses: {
      25437: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      639054: '0x98e7E75EeB1a1d5E594ecE6Fa25B596b6C0b16dF',
    },
    token: serializedTokens.bcusdt,
    quoteToken: serializedTokens.wbcc,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-BCC LP',
  //   lpAddresses: {
  //     25437: '',
  //     639054: '0x8c213E3170bC941b56eD76eC2FAE884C79074bCE',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wbcc,
  // },


  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
